import { ProtectedRoute } from '@shared/auth';
import { toString } from 'lodash';
import React, { Suspense } from 'react';
import { LiaBookMedicalSolid, LiaBoxesSolid, LiaEditSolid, LiaTagsSolid } from 'react-icons/lia';
import { Outlet } from 'react-router-dom';

import { OverlayLoader } from '@/components/loading';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { MetaData, MetaDataItem } from '@/components-new/meta-data';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Tab, TabGroup, TabList, TabPanels } from '@/components-new/tab';
import { useDrug } from '@/features/drugs/api/use-drug';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useTabs } from '@/hooks/use-tabs';
import { useParams } from '@/router';
import { insertIf } from '@/utils/arrays';


const DrugLayoutScreen = () => {
  const { id: drugId } = useParams('/drugs/:id');
  const { data: drug } = useDrug(drugId);
  const { hasPolicies } = useCurrentUser();
  const canManageDrugs = hasPolicies(['canManageDrugs']);
  const isAnyArtiaUser = hasPolicies(['isAnyArtiaUser']);

  const {
    tabs,
    activeTabIndex,
    onChange
  } = useTabs([
    {
      label: (
        <>
          <LiaBookMedicalSolid />
          Coverage
        </>
      ),
      href: `/drugs/${drugId}`,
    },
    ...insertIf(isAnyArtiaUser,
      {
        label: (
          <>
            <LiaBoxesSolid />
            Packagings
          </>
        ),
        href: `/drugs/${drugId}/packagings`,
      },
    ),
    ...insertIf(isAnyArtiaUser,
      {
        label: (
          <>
            <LiaTagsSolid />
            Coverage Tags
          </>
        ),
        href: `/drugs/${drugId}/coverage-tags`,
      }
    )
  ]);

  return (
    <Page
      title={drug.name}
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drugs', url: '/drugs' },
              { label: drug.name, url: `/drugs/${drug.id}` }
            ]}
          />
          <PageTitleRow
            title={title}
            subtitle={
              <MetaData>
                {drug.genericName && <MetaDataItem>{drug.genericName}</MetaDataItem>}
                {drug.classification && <MetaDataItem>{drug.classification.name}</MetaDataItem>}
              </MetaData>
            }
          >
            {canManageDrugs && (
              <Button
                href={{
                  to: '/drugs/:id/edit',
                  params: { id: toString(drugId) },
                  state: { referrer: `/drugs/${drug.id}` }
                }}
                color="secondary"
                aria-label={`Edit ${drug.name}`}
                title={`Edit ${drug.name}`}
              >
                <LiaEditSolid />
                Edit
              </Button>
            )}
          </PageTitleRow>
        </>
      )}
    >
      <TabGroup selectedIndex={activeTabIndex} onChange={onChange}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.href}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <div className="mt-2">
            <Outlet />
          </div>
        </TabPanels>
      </TabGroup>
    </Page>
  );
};

const DrugLayout = () => {
  return (
    <ProtectedRoute>
      <Suspense fallback={<OverlayLoader />}>
        <DrugLayoutScreen />
      </Suspense>
    </ProtectedRoute>
  );
};

export default DrugLayout;
