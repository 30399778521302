import { Text } from 'grommet';
import React from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { MarketBasketDrugPackaging } from '@/features/market-baskets/types/market-basket';
import { Button } from '@/components-new/button';

type DeleteNdcsDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  selectedDrugPackagings?: MarketBasketDrugPackaging[];
  busy?: boolean;
};

export const DeleteNdcsDialog = (props: DeleteNdcsDialogProps ) => {
  const {
    busy,
    onCancel,
    onSubmit,
    open,
    selectedDrugPackagings = [],
  } = props;

  return (
    <Dialog open={open}>
      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
      <DialogHeader title={`Remove ${selectedDrugPackagings.length} NDC(s) from Market Basket?`} onClose={busy ? null : onCancel}/>
      <DialogBody>
        <Text>You are about to remove {selectedDrugPackagings.length} NDC(s) from this market basket. Are you sure you want to continue? This cannot be undone.</Text>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onCancel} disabled={busy}>Cancel</Button>
        <Button onClick={onSubmit} disabled={busy}>
          <Busy busy={busy} content="Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
