import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { Timestamp } from '@/components/date-time';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxGroup } from '@/components-new/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { useNotifications } from '@/features/notifications/api/use-notifications';
import { Notification } from '@/features/notifications/types/notification';
import { useUpdateNotificationStatuses } from '@/features/notifications/api/use-update-notification-status';
import { HiOutlineEnvelope, HiOutlineEnvelopeOpen, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi2';

const NotificationsPage = () => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>(undefined);

  const { data: notifications } = useNotifications();
  const { mutate } = useUpdateNotificationStatuses();

  const canManageNotifications = true; // TODO: Permissions

  useEffect(() => {
    const isIndeterminate = selectedNotifications.length > 0 && selectedNotifications.length < notifications.length;
    setChecked(selectedNotifications.length === notifications.length);
    setIndeterminate(isIndeterminate);
  }, [notifications.length, selectedNotifications]);

  const toggleAll = () => {
    setSelectedNotifications(checked || indeterminate ? [] : notifications);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const updateSelected = ( read: boolean, willDelete: boolean ) => {
    const statuses = selectedNotifications.map((selectedNotification) => {
      return { id: selectedNotification.id, read: read, delete: willDelete };
    });
    mutate(statuses, {
      onSuccess: () => setSelectedNotifications([])
    });
  };

  const readStateAction = selectedNotifications.some(notification => notification.read) ? 'unread' : 'read';

  return (
    <>
      <Seo title="Notifications"/>
      <PageTitleRow title="Notifications">
        {canManageNotifications && (
          <Button
            color="secondary"
            aria-label="New Post"
            href="/notifications/new"
          >
            <HiOutlinePlus/>
            Add New
          </Button>
        )}
      </PageTitleRow>

      <div className="relative mt-8">
        {selectedNotifications.length > 0 && (
          <div className="absolute left-16 top-0 flex h-12 items-center space-x-3 bg-white sm:left-16">
            {readStateAction == 'unread' && (
              <Button outline title="Mark as unread" onClick={() => updateSelected(false, false)}>
                <HiOutlineEnvelope/>
              </Button>
            )}
            {readStateAction == 'read' && (
              <Button outline title="Mark as read" onClick={() => updateSelected(true, false)}>
                <HiOutlineEnvelopeOpen/>
              </Button>
            )}
            <Button outline title="Delete" onClick={() => updateSelected(true, true)}>
              <HiOutlineTrash/>
            </Button>
          </div>
        )}

        <table className="min-w-full table-fixed divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
              <CheckboxGroup>
                <Checkbox
                  color="secondary"
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={toggleAll}
                />
              </CheckboxGroup>
            </th>
            <th scope="col" className="min-w-48 py-3.5 pr-3 text-left text-sm font-normal text-zinc-700">
              Message
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-zinc-700">
              Sent At
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {notifications.map((notification) => (
            <tr key={notification.id} className={selectedNotifications.includes(notification) ? 'bg-gray-50' : undefined}>
              <td className="relative px-7 sm:w-12 sm:px-6">
                {selectedNotifications.includes(notification) && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-950"/>
                )}
                <CheckboxGroup>
                  <Checkbox
                    color="secondary"
                    checked={selectedNotifications.includes(notification)}
                    value={notification.id.toString()}
                    onChange={(e) =>
                      setSelectedNotifications(
                        e
                          ? [...selectedNotifications, notification]
                          : selectedNotifications.filter((it) => it !== notification),
                      )
                    }
                  />
                </CheckboxGroup>
              </td>
              <td
                className={clsx(
                  'cursor-pointer whitespace-nowrap py-4 pr-3 text-sm font-medium',
                  selectedNotifications.includes(notification) ? 'text-primary-950' : 'text-gray-900',
                )}
                onClick={() => {
                  setCurrentNotification(notification);
                  setDialogOpen(true);
                  if (!notification.read) mutate([{ id: notification.id, read: true, delete: false }]);
                }}
              >
                <p className={clsx(notification.read ? 'font-normal' : 'font-bold')}>
                  {notification.title}
                </p>
                <p dangerouslySetInnerHTML={{ __html: notification.abstract }}/>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Timestamp wordBreak="break-word" color="text-strong" value={notification.sentAt}/>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} size="5xl">
        <DialogTitle>{currentNotification?.title}</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {currentNotification?.content && (
            <div dangerouslySetInnerHTML={{ __html: currentNotification.content }}/>
          )}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationsPage;
