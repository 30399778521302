import { useErrorHandler } from '@shared/errors';
import { Box, FileInput, Form, FormExtendedEvent, FormField, List, RadioButtonGroup, Text } from 'grommet';
import React, { useId, useState } from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { notifySuccess } from '@/lib/notification/notifications';

import { useStatesService } from '../api';
import { ColumnConfiguration } from '../types';

type StateImportDialogProps = {
  open: boolean;
  columnConfigurations: ColumnConfiguration[];
  onCancel: () => void;
  onImportCompletion: () => void;
};

const DEFAULT_FORMAT = 'full';

export const StateImportDialog = ({ open, columnConfigurations, onCancel, onImportCompletion }: StateImportDialogProps) => {
  const formId = useId();
  const { importStatesData, importStateLives } = useStatesService();
  const [saving, setSaving] = useState(false);
  const [format, setFormat] = useState<'full' | 'lives'>(DEFAULT_FORMAT);
  const { handleError } = useErrorHandler();

  const validateFile = (file: File[]) => {
    if (!file || file.length === 0) {
      return 'File is required';
    }

    if (file[0].type !== 'text/csv') {
      return 'File must be a CSV';
    }
  };

  const handleCancel = () => {
    setFormat(DEFAULT_FORMAT);
    onCancel();
  };

  const handleSubmit = async (event: FormExtendedEvent<unknown, HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      setSaving(true);

      if (format === 'lives') {
        await importStateLives(formData);
      } else {
        await importStatesData(formData);
      }

      notifySuccess({ title: 'Import Succeeded', message: 'Successfully imported State information.' });

      setFormat(DEFAULT_FORMAT);
      onImportCompletion();
    } catch (e) {
      handleError(
        e as Error,
        {
          title: 'Import Failed',
          message: 'We encountered an unexpected error while importing State information. Please try again or contact an administrator.'
        }
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog open={open} width="xlarge">
      <DialogHeader title="Import" onClose={handleCancel} />
      <DialogBody>
        <Text as="label" margin="none" weight="bold">Format</Text>
        <BorderlessFormField required name="format" htmlFor={`${formId}-format`}>
          <RadioButtonGroup
            onChange={(e) => setFormat(e.target.value as 'lives' | 'full')}
            value={format}
            direction="row"
            name="format"
            id={`${formId}-format`}
            options={[{ value: 'lives', label: 'State Lives' }, { value: 'full', label: 'Full' }]}
          />
        </BorderlessFormField>
        {/* @ts-expect-error TS(2322): Type '(event: FormExtendedEvent<unknown, HTMLFormE... Remove this comment to see the full error message */}
        <Form id={formId} onSubmit={handleSubmit}>
          <Box direction="column" gap="medium">
            <FormField name="Upload" htmlFor={`${formId}-upload`} label="CSV File" validate={validateFile} required>
              <FileInput multiple={false} name="Upload" id={`${formId}-upload`} accept=".csv"/>
            </FormField>
            {format === 'full' ? (
              <Box direction="column" gap="medium">
                <Text>
                  Unless specified as required, all of the columns below are optional. Drugs and classes carved out from
                  MCO or exempt from the PDL will not be handled by this import.
                </Text>
                <List
                  primaryKey="header"
                  secondaryKey="description"
                  data={[
                    {
                      header: 'ST (Required)',
                      description: 'The two-letter state code. Necessary to identify the state to update.'
                    },
                    ...columnConfigurations
                  ]}
                />
              </Box>
            ) : null}
            {format === 'lives' ? (
              <Text>
                Total Lives and State Controlled Lives will automatically be calculated for you,
                so you don't have to include them.
              </Text>
            ) : null}
          </Box>
        </Form>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={handleCancel}
          disabled={saving}
        >Cancel</Button>
        <Button
          disabled={format === null || saving}
          form={formId}
          type="submit"
        >
          <Busy busy={saving} content="Import" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
