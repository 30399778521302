import { useAuthorization } from '@shared/auth/use-authorization';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnConfig, DataTable, Text } from 'grommet';
import React, { useMemo, useState } from 'react';

import { ActionBar } from '@/components/action-bar';
import { useDrug } from '@/features/drugs/api/use-drug';
import NdcCell from '@/features/drugs/components/ndc-cell';
import { brandGenericStatusOptions } from '@/features/drugs/types/brand-generic-status';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';
import { useParams } from '@/router';
import { formatCurrency } from '@/utils/formatting';

import { MovePackagingsDialog } from './move-packagings-dialog';
import { PackagingCoverageTagsCell } from './packaging-coverage-tags-cell';
import { PackagingFilterData } from './packaging-filters';
import { Button } from '@/components-new/button';

type PackagingsTableProps = {
  filters: PackagingFilterData;
};

export const PackagingsTable = ({ filters }: PackagingsTableProps) => {
  const { id } = useParams('/drugs/:id');
  const { data: drug } = useDrug(parseInt(id));
  const queryClient = useQueryClient();

  const { checkPolicies } = useAuthorization();
  const canManageCoverageTags = checkPolicies(['canManageCoverageTags']);
  const canManageDrugs = checkPolicies(['canManageDrugs']);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isMovePackagingsDialogOpen, setIsMovePackagingsDialogOpen] = useState(false);

  const filteredPackagings = useMemo(() => {
    if (!drug?.packagings) {
      return [];
    }

    const { ndc, description } = filters;

    return drug.packagings.filter(packaging =>
      (!ndc || packaging.ndc.includes(ndc))
      && (!description || packaging.description.toLowerCase().includes(description.toLowerCase()))
    );
  }, [filters, drug]);

  const closeMovePackagingsDialog = () => setIsMovePackagingsDialogOpen(false);
  const openMovePackagingsDialog = () => setIsMovePackagingsDialogOpen(true);

  const handleMovePackagingsSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['drug'] })
      .then(() => {
        closeMovePackagingsDialog();
        setSelectedRows([]);
      });
  };

  const handleOnSelect = (selected: (string | number)[]) => {
    setSelectedRows(selected.map(String));
  };

  const columns: ColumnConfig<DrugPackaging>[] = [
    {
      property: 'ndc',
      header: 'NDC',
      render: (packaging: DrugPackaging) => <NdcCell product={drug} packaging={packaging} />
    },
    {
      property: 'description',
      header: 'Description',
    },
    {
      property: 'coverageTags',
      header: 'Coverage Tags',
      sortable: false,
      render: (packaging) => (
        <PackagingCoverageTagsCell
          drug={drug}
          canManageCoverageTags={canManageCoverageTags}
          packaging={packaging}
        />
      )
    },
    {
      property: 'brandGenericStatus',
      header: 'Brand/Generic Status',
      render: (item) => brandGenericStatusOptions.find(option => option.value === item.brandGenericStatus)?.label
    },
    {
      property: 'onMarketWacPrice',
      header: 'On Market WAC Price',
      align: 'end',
      render: (packaging) => packaging.onMarketWacPrice ? formatCurrency(packaging.onMarketWacPrice) : <>&mdash;</>
    },
    {
      property: 'onMarketWacUnitPrice',
      header: 'On Market WAC Unit Price',
      align: 'end',
      render: (packaging) => packaging.onMarketWacUnitPrice ? formatCurrency(packaging.onMarketWacUnitPrice) : <>&mdash;</>
    },
    {
      property: 'baselineCpiu',
      header: 'Baseline CPI-U',
      align: 'end',
      render: (packaging) => packaging.baselineCpiu ? packaging.baselineCpiu : <>&mdash;</>
    }
  ];

  return (
    <>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <DataTable
        primaryKey="ndc"
        allowSelectAll={true}
        select={canManageDrugs ? selectedRows : null}
        onSelect={canManageDrugs ? handleOnSelect : null}
        columns={columns}
        data={filteredPackagings}
        sortable
        step={10}
        paginate
      />

      <ActionBar isOpen={selectedRows.length > 0}>
        <Text alignSelf="center">{selectedRows.length} of {filteredPackagings.length} Selected</Text>
        <Button onClick={openMovePackagingsDialog}>
          Move NDCs
        </Button>
      </ActionBar>

      <MovePackagingsDialog
        isOpen={isMovePackagingsDialogOpen}
        packagings={selectedRows}
        onCancel={closeMovePackagingsDialog}
        onSuccess={handleMovePackagingsSuccess}
      />
    </>
  );
};
