import { Box } from 'grommet';
import React from 'react';
import { Button } from '@/components-new/button';
import { HiOutlineEye } from 'react-icons/hi2';

type ClinicalsChangeLogActionsCellProps = {
  onViewSnapshot: () => void;
};

export const ClinicalsChangeLogActionsCell = (props: ClinicalsChangeLogActionsCellProps) => {
  const { onViewSnapshot } = props;

  const handleViewSnapshot = () => onViewSnapshot();

  return (
    <Box direction="row" align="center">
      <Button
        plain
        title="View Snapshot"
        aria-label="View Snapshot"
        onClick={handleViewSnapshot}
      >
        <HiOutlineEye/>
      </Button>
    </Box>
  );
};
