import { Box, Spinner } from 'grommet';
import React, { useEffect, useMemo } from 'react';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { DrugPackagingsForm } from '@/components/drug-packagings-form';
import { MarketBasketDrugPackaging } from '@/features/market-baskets/types/market-basket';
import { useDrugPackagingForm } from '@/hooks/use-drug-packaging-form';
import { Button } from '@/components-new/button';

type EditDrugPackagingDialogProps = {
  marketBasketDrugPackaging: MarketBasketDrugPackaging;
  isOpen: boolean;
  onClose: () => void;
  onSaved: () => void;
};

export const EditDrugPackagingDialog = (props: EditDrugPackagingDialogProps) => {
  const {
    isOpen,
    onClose,
    onSaved,
    marketBasketDrugPackaging,
  } = props;

  const {
    form,
    submitForm,
    savingDrugPackaging,
    fetchDrugPackaging,
    fetchingDrugPackaging,
    savedDrugPackagingSuccessfully
  } = useDrugPackagingForm();

  const drugPackagingName = useMemo(() => {
    return !marketBasketDrugPackaging ?
      '' :
      `${marketBasketDrugPackaging.ndc} - ${marketBasketDrugPackaging?.productName} ${marketBasketDrugPackaging?.name}`;
  }, [marketBasketDrugPackaging]);

  useEffect(() => {
    if (!savedDrugPackagingSuccessfully) return;
    form.reset();
    onSaved();
  }, [savedDrugPackagingSuccessfully]);

  useEffect(() => {
    if (!isOpen) return;

    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    fetchDrugPackaging(marketBasketDrugPackaging.productId, marketBasketDrugPackaging.ndc);
  }, [isOpen]);

  const handleFormSubmit = () => {
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    submitForm(marketBasketDrugPackaging.productId, marketBasketDrugPackaging.ndc);
  };

  const handleDialogClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} width="large">
      <DialogHeader
        // @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message
        onClose={!savingDrugPackaging && handleDialogClose}
        title="Edit Drug Packaging"
        subtitle={drugPackagingName}
      />
      <DialogBody>
        <Box fill="horizontal" gap="medium">
          <Box gap="small">
            {fetchingDrugPackaging && (
              <Box align="center">
                <Spinner size="large" />
              </Box>
            )}
            {!fetchingDrugPackaging && <DrugPackagingsForm formControl={form.control} errors={form.formState.errors} /> }
          </Box>
        </Box>
      </DialogBody>
      <DialogActions>
        <Box direction="row" gap="small">
          <Button
            plain
            onClick={handleDialogClose}
            disabled={savingDrugPackaging}
          >Cancel</Button>
          <Button
            onClick={handleFormSubmit}
            disabled={!form.formState.isValid || savingDrugPackaging || savingDrugPackaging}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
