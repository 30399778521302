import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { useClient } from '@/features/clients/api/use-client';
import { useCreateClientUser } from '@/features/clients/api/use-create-client-user';
import CreateUpdateClientUserForm from '@/features/clients/components/create-update-client-user-form';
import { CreateUpdateClientUserFormSchema, createUpdateClientUserFormSchema } from '@/features/clients/models/create-update-client-user-form-schema';
import { useNavigate, useParams } from '@/router';
import { Button } from '@/components-new/button';

const NewUserPage = () => {
  const { id } = useParams('/clients/:id/users/new');
  const navigate = useNavigate();
  const { data: client } = useClient(parseInt(id));
  const {
    mutate: createClientUser,
    isPending: isCreatingUser,
  } = useCreateClientUser();

  const form = useForm<CreateUpdateClientUserFormSchema>({
    resolver: zodResolver(createUpdateClientUserFormSchema),
  });

  const handleDismiss = () => {
    navigate('/clients/:id/users', { params: { id } });
  };

  const handleSubmit = (value: CreateUpdateClientUserFormSchema) => {
    createClientUser(
      { clientId: parseInt(id), form: value },
      {
        onSuccess: () => {
          navigate('/clients/:id/users', { params: { id } });
        }
      }
    );
  };

  const isSubmitDisabled = !form.formState.isValid || isCreatingUser;

  return (
    <Dialog open={true} onClickOutside={handleDismiss} width="large">
      <DialogHeader title="Add User" onClose={handleDismiss}/>
      <DialogBody>
        <FormProvider {...form}>
          <CreateUpdateClientUserForm client={client} />
        </FormProvider>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={handleDismiss}>Cancel</Button>
        <Button onClick={form.handleSubmit(handleSubmit)} disabled={isSubmitDisabled}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserPage;
