import { PaginationResult } from '@shared/pagination';
import { maxLength, required, validateIf } from '@shared/validators';
import { Box, FormField, RadioButton, Text, TextInput } from 'grommet';
import React, { ReactNode, useEffect, useId } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { LazyLoadSelectList } from '@/components/form-controls';
import { DrugLookupSelect } from '@/features/market-baskets/components/drug-lookup-select';
import {
  DRUG_LOOKUP_ASSOCIATION,
  DrugLookupAssociationType
} from '@/features/market-baskets/types/drug-lookup-associations';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';
import { Button } from '@/components-new/button';

type DrugLookupDialogProps = {
  header: ReactNode;
  hint?: string;
  open: boolean;
  onSubmit: (data: FormInputs) => void;
  onClose: () => void;
  mode: 'existing-only' | 'new-and-existing';
  saving: boolean;
  submitButtonLabel: ReactNode;
};

export const DrugLookupDialog = (props: DrugLookupDialogProps) => {
  const {
    header,
    hint,
    open,
    onSubmit,
    onClose,
    mode,
    saving,
    submitButtonLabel
  } = props;

  const formId = useId();

  const {
    control,
    formState: { errors, isValid, },
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    mode: 'all',
    defaultValues: {
      associationType: DRUG_LOOKUP_ASSOCIATION.EXISTING_PRODUCT,
      productName: ''
    }
  });

  useEffect(() => {
    reset();
    // TODO: review this effect, it depends on a value that it doesn't use and is missing the dependency it does use
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const associationType = useWatch({ control, name: 'associationType' });
  const associatingWithExistingProduct = associationType === DRUG_LOOKUP_ASSOCIATION.EXISTING_PRODUCT;
  const creatingNewProduct = associationType === DRUG_LOOKUP_ASSOCIATION.NEW_PRODUCT;
  const submitDisabled = !isValid || saving;

  const { getClientLookups } = useLookupsService();

  return (
    <Dialog open={open}>
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title={header} onClose={!saving && onClose} />
      <DialogBody>
        {hint && <Box margin={{ bottom: 'small' }}>
          <Text>{hint}</Text>
        </Box>}
        <Box id={formId} as="form" fill="horizontal" gap="medium" onSubmit={handleSubmit(onSubmit)}>
          <Box gap="small">
            {mode === 'new-and-existing' ? (
              <Controller
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <RadioButton
                    name="associationType"
                    label="Select existing product"
                    checked={value === DRUG_LOOKUP_ASSOCIATION.EXISTING_PRODUCT}
                    onChange={onChange}
                    value={DRUG_LOOKUP_ASSOCIATION.EXISTING_PRODUCT}
                    ref={ref}
                  />
                )}
                name="associationType"
              />
            ) : null}
            <Controller
              control={control}
              rules={{
                validate: {
                  required: validateIf(associatingWithExistingProduct, required('Product is required'))
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <DrugLookupSelect
                  required={associatingWithExistingProduct}
                  disabled={!associatingWithExistingProduct}
                  error={associatingWithExistingProduct && error?.message}
                  {...field}
                />
              )}
              name="product"
            />
          </Box>
          {mode === 'new-and-existing' ? (
            <Box gap="small">
              <Controller
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <RadioButton
                    name="associationType"
                    label="Create new product"
                    checked={value === DRUG_LOOKUP_ASSOCIATION.NEW_PRODUCT}
                    onChange={onChange}
                    value={DRUG_LOOKUP_ASSOCIATION.NEW_PRODUCT}
                    ref={ref}
                  />
                )}
                name="associationType"
              />
              <Box direction="row" gap="small">
                <Controller
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: {
                      required: validateIf(creatingNewProduct, required('Product name is required')),
                      maxLength: validateIf(creatingNewProduct, maxLength(100, 'Product name must be less than 100 characters'))
                    }
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FormField
                      required
                      name="productName"
                      label="Product Name"
                      htmlFor="productName"
                      disabled={!creatingNewProduct}
                      error={creatingNewProduct && errors.productName?.message}
                    >
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={!creatingNewProduct}
                        id="productName"
                        name="productName"
                        ref={ref}
                      />
                    </FormField>
                  )}
                  name="productName"
                />
                <Controller
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LazyLoadSelectList
                      error={error?.message}
                      label="Client"
                      disabled={!creatingNewProduct}
                      placeholder="Select a client"
                      {...field}
                      lazyLoadRequest={async (searchTerm, page, rpp) => {
                        const result = await getClientLookups({ query: searchTerm, page, rpp });
                        return result as PaginationResult<Lookup>;
                      }}
                    />
                  )}
                  name="client"
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose} disabled={saving}>Cancel</Button>
        <Button
          type="submit"
          form={formId}
          disabled={submitDisabled}
        >
          <Busy busy={saving} content={submitButtonLabel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FormInputs = {
  associationType: DrugLookupAssociationType;
  productName: string;
  client: Lookup;
  product: Lookup;
};
