import { Box, ColumnConfig, DataTable } from 'grommet';
import React from 'react';

import { Timestamp } from '@/components/date-time';
import { TableEmptyPlaceholder } from '@/components/loading';
import { Attachment } from '@/types/attachment';
import { Button } from '@/components-new/button';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';

type AttachmentsTableProps = {
  attachments: Attachment[];
  onDownloadHandler: (url: string) => void;
};

export const AttachmentsTable = ({ attachments, onDownloadHandler }: AttachmentsTableProps) => {
  const columns: ColumnConfig<Attachment>[] = [
    {
      property: 'filename',
      header: 'File Name'
    },
    {
      property: 'createdAt',
      header: 'Created At',
      render: (attachment) => <Timestamp value={attachment.createdAt} />
    },
    {
      property: 'actions',
      sortable: false,
      render: (attachment) => (
        <Box direction="row" gap="xxsmall" alignSelf="end">
          <Button
            plain
            title="Download"
            onClick={() => onDownloadHandler(attachment.url)}
            aria-label="Download"
          >
            <HiOutlineArrowDownTray/>
          </Button>
        </Box>
      )
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={attachments}
      sortable
      step={10}
      paginate
      primaryKey="id"
      placeholder={
        (attachments.length === 0) &&
        <Box fill>
          <TableEmptyPlaceholder content="No attachments have been added." />
        </Box>
      }
    />
  );
};
