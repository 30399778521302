import { Box, Grid, Heading, Text } from 'grommet';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Chip } from '@/components/chip';
import { Visible } from '@/components/visible';
import { useCurrentUser } from '@/hooks/use-current-user';
import { BoardMeeting, displayMeetingFormat, MeetingFormat } from '@/types/board-meetings';

import { TooltipList } from './tooltip-list';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi2';
import { Button } from '@/components-new/button';

type BoardMeetingListProps = {
  /**
   * List of board meetings to render, required.
   */
  boardMeetings: BoardMeeting[];

  /**
   * Event handler for when a user clicks the edit button of one of the list items.
   * @param boardMeeting the board meeting the user wants to edit
   */
  onEditSelection: (boardMeeting: BoardMeeting) => void;

  /**
   * Event handler for when a user clicks the delete button of one of the list items.
   * @param boardMeeting the board meeting the user wants to delete
   */
  onDeleteSelection: (boardMeeting: BoardMeeting) => void;
};

/**
 * Main list view of the Board Meetings. Allows admin to view, edit, or delete board meetings.
 */
export const BoardMeetingList = ({ boardMeetings, onEditSelection, onDeleteSelection }: BoardMeetingListProps) => {
  const { hasPolicies } = useCurrentUser();

  const groupedBoardMeetings = useMemo(() => {
    const result = new Map<string, BoardMeeting[]>();
    let currentMonthYear: string | null = null;

    for (const boardMeeting of boardMeetings) {
      const monthYear = boardMeeting.startTime.setZone(boardMeeting.ianaTimeZone).toFormat('MMMM yyyy');
      if (currentMonthYear !== monthYear) {
        currentMonthYear = monthYear;
      }

      if (result.has(currentMonthYear)) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        result.get(currentMonthYear).push(boardMeeting);
      } else {
        result.set(currentMonthYear, [boardMeeting]);
      }
    }

    return [...result];
  }, [boardMeetings]);

  if (groupedBoardMeetings.length === 0) {
    return <>No Board Meetings match your search criteria. Try broadening your search or creating a new one.</>;
  }

  return (
    <Box direction="column" gap="medium" width="100%">
      {groupedBoardMeetings.map(([monthYear, boardMeetings]) => (
        <Box direction="column" gap="small" key={monthYear}>
          <Heading level="3" margin="none">{monthYear}</Heading>
          <Box direction="column">
            {boardMeetings.map(boardMeeting => (
              <BoardMeetingListItem
                key={boardMeeting.id}
                canManageCalendar={hasPolicies(['canManageCalendar'])}
                boardMeeting={boardMeeting}
                onEditSelection={onEditSelection}
                onDeleteSelection={onDeleteSelection}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type BoardMeetingListItemProps = {
  boardMeeting: BoardMeeting;
  onEditSelection: (boardMeeting: BoardMeeting) => void;
  onDeleteSelection: (boardMeeting: BoardMeeting) => void;
  canManageCalendar: boolean;
};

const BoardMeetingListItem = ({ boardMeeting, onEditSelection, onDeleteSelection, canManageCalendar }: BoardMeetingListItemProps) => {
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: boardMeeting.ianaTimeZone
  };

  let chipColor: string | null = null;

  switch (boardMeeting.format) {
    case MeetingFormat.VIRTUAL:
      chipColor = 'accent-2';
      break;
    case MeetingFormat.IN_PERSON:
      chipColor = 'warning';
      break;
    case MeetingFormat.HYBRID:
      chipColor = 'accent-1';
      break;
  }

  return (
    <BoardMeetingListItemGrid
      rows={['min-content']}
      columns={['max-content', '1fr', 'max-content', 'max-content']}
      width="100%"
      gap="large"
      align="center"
      pad="medium"
    >
      <Text>{boardMeeting.startTime.toLocaleString(dateFormatOptions)}</Text>
      <Box direction="row" gap="medium">
        <span>{boardMeeting.state.name} {boardMeeting.meetingType.label} Meeting</span>
        <TooltipList
          items={boardMeeting.discussedClassifications}
          singularName="Class"
          pluralAddition="es"
        />
        <TooltipList
          items={boardMeeting.discussedDrugs}
          singularName="Drug"
          pluralAddition="s"
        />
      </Box>
      <Box direction="row" gap="small" justify="end" align="center" width="100%">
        <Chip
          chip={{ label: displayMeetingFormat(boardMeeting.format), value: boardMeeting.format }}
          background={chipColor}
        />
      </Box>
      <Visible when={canManageCalendar}>
        <Box direction="row" gap="small" justify="end" align="center">
          <Button
            plain
            title="Edit"
            aria-label="Edit"
            onClick={() => onEditSelection(boardMeeting)}
          >
            <HiOutlinePencil/>
          </Button>
          <Button
            plain
            title="Delete"
            aria-label="Delete"
            onClick={() => onDeleteSelection(boardMeeting)}
          >
            <HiOutlineTrash/>
          </Button>
        </Box>
      </Visible>
    </BoardMeetingListItemGrid>
  );
};

const BoardMeetingListItemGrid = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['dark-3']};

  &:first-child {
    border-top: 2px solid ${({ theme }) => theme.global.colors['dark-1']};
  }
`;
