import { zodResolver } from '@hookform/resolvers/zod';
import { ProtectedRoute } from '@shared/auth';
import { Box } from 'grommet';
import { parseInt } from 'lodash';
import { Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import CheckboxSkeleton from '@/components/loading/checkbox-skeleton';
import FormFieldSkeleton from '@/components/loading/form-field-skeleton';
import TextSkeleton from '@/components/loading/text-skeleton';
import { useCoverageTag } from '@/features/drugs/api/use-coverage-tag';
import { useDrug } from '@/features/drugs/api/use-drug';
import { useUpdateCoverageTag } from '@/features/drugs/api/use-update-coverage-tag';
import CreateUpdateCoverageTagForm from '@/features/drugs/components/create-update-coverage-tag-form';
import { useEditCoverageTagDismiss } from '@/features/drugs/hooks/use-edit-coverage-tag-dismiss';
import {
  CreateUpdateCoverageTagFormSchema,
  createUpdateCoverageTagFormSchema
} from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { useNavigate, useParams } from '@/router';
import { Button } from '@/components-new/button';

const EditCoverageTagDialog = () => {
  const dismiss = useEditCoverageTagDismiss();
  const navigate = useNavigate();
  const { id, tagId } = useParams('/drugs/:id/coverage-tags/:tagId/edit');
  const { data: drug } = useDrug(parseInt(id));
  const { data: coverageTag } = useCoverageTag(parseInt(id), parseInt(tagId));
  const { mutate: updateCoverageTag, isPending } = useUpdateCoverageTag();

  const form = useForm<CreateUpdateCoverageTagFormSchema>({
    resolver: zodResolver(createUpdateCoverageTagFormSchema)
  });

  const handleSubmit = (value: CreateUpdateCoverageTagFormSchema) => {
    updateCoverageTag(
      { drugId: parseInt(id), coverageTagId: parseInt(tagId), form: value },
      {
        onSuccess: () => {
          navigate('/drugs/:id/coverage-tags', { params: { id } });
        }
      }
    );
  };

  const isSubmitDisabled = !form.formState.isValid || isPending;

  return (
    <>
      <DialogHeader
        title="Edit Coverage Tag"
        subtitle={coverageTag.name}
        onClose={dismiss}
      />
      <DialogBody>
        <FormProvider {...form}>
          <CreateUpdateCoverageTagForm drug={drug} coverageTag={coverageTag} />
        </FormProvider>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={dismiss}>Cancel</Button>
        <Button disabled={isSubmitDisabled} onClick={form.handleSubmit(handleSubmit)}>Save</Button>
      </DialogActions>
    </>
  );
};

const EditCoverageTagDialogSkeleton = () => {
  const dismiss = useEditCoverageTagDismiss();

  return (
    <>
      <DialogHeader
        onClose={dismiss}
        title={<TextSkeleton width="medium" height="2rem" />}
        subtitle={<TextSkeleton width="medium" />}
      />
      <DialogBody>
        <Box gap="medium">
          <FormFieldSkeleton />
          <FormFieldSkeleton />
          <Box gap="small">
            <TextSkeleton width="small" />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
          </Box>
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={dismiss}>Cancel</Button>
        <Button disabled>Save</Button>
      </DialogActions>
    </>
  );
};

const EditCoverageTagPage = () => {
  const dismiss = useEditCoverageTagDismiss();

  return (
    <ProtectedRoute policies={['canManageCoverageTags']}>
      <Dialog open={true} onClickOutside={dismiss} width="large">
        <Suspense fallback={<EditCoverageTagDialogSkeleton />}>
          <EditCoverageTagDialog />
        </Suspense>
      </Dialog>
    </ProtectedRoute>
  );
};

export default EditCoverageTagPage;
