import { ProtectedRoute } from '@shared/auth';
import { Box, Pagination, Spinner, Text } from 'grommet';
import React, { useState } from 'react';

import { Visible } from '@/components/visible';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { BoardMeetingFilterData } from '@/features/board-meetings/api/use-board-meeting-service';
import { useBoardMeetings } from '@/features/board-meetings/api/use-board-meetings';
import {
  BoardMeetingFilters,
  BoardMeetingList,
  BoardMeetingsProvider,
  CreateBoardMeetingDialog,
  UpdateBoardMeetingDialog
} from '@/features/board-meetings/components';
import { DeleteBoardMeetingDialog } from '@/features/board-meetings/components/delete-board-meeting-dialog';
import { useCurrentUser } from '@/hooks/use-current-user';
import { BoardMeeting } from '@/types/board-meetings';
import { Button } from '@/components-new/button';
import { HiOutlinePlus } from 'react-icons/hi2';

type DialogState = {
  action: 'delete' | 'create' | 'update' | 'idle',
  boardMeeting?: BoardMeeting;
};

const BoardMeetingsPage = () => {
  const { hasPolicies } = useCurrentUser();
  const {
    boardMeetings,
    loading,
    refresh,
    filterData,
    page,
    resultsPerPage,
    total,
    setFilterData,
    setPagination
  } = useBoardMeetings();
  const [dialogState, setDialogState] = useState<DialogState>({ action: 'idle' });

  const handleDialogCancel = () => {
    setDialogState({ action: 'idle' });
  };

  const handleDialogComplete = () => {
    setDialogState({ action: 'idle' });
    void refresh(filterData);
  };

  const handleApplyFilters = (filters: BoardMeetingFilterData) => {
    setFilterData(filters);
    setPagination((current) => ({ ...current, page: 1 }));
  };

  return (
    <>
      <PageTitleRow title="Board Meetings" subtitle="Manage and view upcoming State board meetings.">
        <Visible when={hasPolicies(['canManageCalendar'])}>
          <Button aria-label="Add Meeting" onClick={() => setDialogState({ action: 'create' })}>
            <HiOutlinePlus/>
            Add New
          </Button>
        </Visible>
      </PageTitleRow>
      <Seo title="Board Meetings"/>
      <BoardMeetingsProvider>
        <Box direction="column" gap="medium" pad={{ bottom: 'medium' }}>
          <BoardMeetingFilters defaultValues={filterData} onSubmit={handleApplyFilters} />
          {boardMeetings.length === 0 && loading ? (
            <Box direction="column" align="center">
              <Spinner size="large" />
              <Text>Loading Board Meetings...</Text>
            </Box>
          ) : (
            <Box direction="column" gap="medium" align="center" width="100%">
              <BoardMeetingList
                boardMeetings={boardMeetings}
                onEditSelection={(boardMeeting) => setDialogState({ action: 'update', boardMeeting })}
                onDeleteSelection={(boardMeeting) => setDialogState({ action: 'delete', boardMeeting })}
              />
              <Pagination
                size="medium"
                page={page}
                step={resultsPerPage}
                numberItems={total}
                onChange={({ page }) => {
                  setPagination((current) => ({ ...current, page }));
                }}
              />
            </Box>
          )}
          <DeleteBoardMeetingDialog
            // @ts-expect-error TS(2322): Type 'BoardMeeting | null | undefined' is not assi... Remove this comment to see the full error message
            boardMeeting={dialogState.action === 'delete' ? dialogState.boardMeeting : null}
            onCancel={handleDialogCancel}
            onDeleteComplete={handleDialogComplete}
          />
          <CreateBoardMeetingDialog
            open={dialogState.action === 'create'}
            onCancel={handleDialogCancel}
            onSubmitComplete={handleDialogComplete}
          />
          <UpdateBoardMeetingDialog
            boardMeeting={dialogState.action === 'update' ? dialogState.boardMeeting : null}
            onCancel={handleDialogCancel}
            onSubmitComplete={handleDialogComplete}
          />
        </Box>
      </BoardMeetingsProvider>
    </>
  );
};

const BoardMeetingsPageContainer = () => {
  return (
    <ProtectedRoute policies={['isAnyArtiaUser']}>
      <BoardMeetingsPage />
    </ProtectedRoute>
  );
};

export default BoardMeetingsPageContainer;
