export enum ChangeLogReason {
  COVERAGE_CHANGE = 'coverage_change',
  PDL_UPDATE = 'pdl_update',
  CORRECTION = 'correction'
}

export function displayChangeLogReason(reason: ChangeLogReason): string | null {
  switch (reason) {
    case ChangeLogReason.CORRECTION:
        return 'Correction';
    case ChangeLogReason.PDL_UPDATE:
        return 'PDL Update';
    case ChangeLogReason.COVERAGE_CHANGE:
        return 'Coverage Change';
  }
}

export const changeLogReasonOptions = [
  ChangeLogReason.COVERAGE_CHANGE,
  ChangeLogReason.PDL_UPDATE,
  ChangeLogReason.CORRECTION
].map((reason) => ({ id: reason, label: displayChangeLogReason(reason) }));
