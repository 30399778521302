import { useErrorHandler } from '@shared/errors';
import { PaginationResult } from '@shared/pagination';
import { Box, Form, Paragraph } from 'grommet';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { LazyLoadSelectList } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { useMovePackagings } from '@/features/drugs/api/use-move-packagings';
import { notifySuccess } from '@/lib/notification/notifications';
import { useGetProductLookups } from '@/products/api/use-get-product-lookups';
import { Lookup } from '@/types/lookup';

type MovePackagingsDialogProps = {
  isOpen: boolean;
  packagings: string[];
  onCancel: () => void;
  onSuccess: () => void;
}

type MovePackagingsFormValues = {
  product: Lookup
}

export const MovePackagingsDialog = ({
  packagings,
  isOpen,
  onCancel,
  onSuccess
}: MovePackagingsDialogProps) => {
  const packagingsCount = packagings.length;
  const form = useForm<MovePackagingsFormValues>();

  const { execute: getProductLookups } = useGetProductLookups();
  const movePackagings = useMovePackagings();
  const { isPending: movingPackagings } = movePackagings;
  const { handleError } = useErrorHandler();

  const loadMoreProductLookups = async (searchTerm: string, page: number, rpp: number) => {
    return await getProductLookups({ query: searchTerm, page, rpp }) as PaginationResult<Lookup>;
  };

  const submitForm = async ({ product }: MovePackagingsFormValues) => {
    await movePackagings.execute({ drugId: product.id, ndcs: packagings });
  };

  useEffect(() => {
    const { isSuccess, isError, error } = movePackagings;

    if (isError) {
      handleError(error, { title: 'Failed to move NDCs' });
      return;
    }

    if (isSuccess) {
      onSuccess();
      notifySuccess( { title: 'Moved Successfully', message: `Successfully moved ${packagingsCount} NDC(s)` });
    }
    // TODO: review this effect, it's missing a lot of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movePackagings.status]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
    // TODO: review this effect, it depends on a value that it doesn't use and is missing the dependency it does use
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} width="large">
      <DialogHeader title={`Move ${packagingsCount} NDC(s)`} onClose={onCancel} />
      <DialogBody>
        <Form>
          <Box direction="column">
            <Controller
              rules={{
                required: 'Please select a product.',
              }}
              // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Lookup<numb... Remove this comment to see the full error message
              defaultValue={null}
              control={form.control}
              render={({ field, fieldState }) => (
                  <LazyLoadSelectList
                    error={fieldState.error?.message}
                    {...field}
                    placeholder="Select a product"
                    label="Product"
                    lazyLoadRequest={loadMoreProductLookups}
                  />
                )}
              name="product"
            />
            <Paragraph size="small" margin={{ bottom: 'none' }} fill={true}>
              <em>Note: If the selected NDCs are associated to any coverage tags, their association will be removed by this action. You may associate these NDCs with coverage tags in the new product when the move is complete.</em>
            </Paragraph>
          </Box>
        </Form>
      </DialogBody>
      <DialogActions>
        <Button disabled={movingPackagings} onClick={onCancel}>Cancel</Button>
        <Button
          onClick={form.handleSubmit(submitForm)}
          disabled={!form.formState.isValid || movingPackagings}
        >
          <Busy busy={movingPackagings} content="Move" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
