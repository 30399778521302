import React from 'react';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { CalculationDetails } from '@/features/market-baskets';
import { Button } from '@/components-new/button';

type CalculationLogDialogProps = {
  open: boolean;
  onClose: () => void;
  calculationDetails?: CalculationDetails
};

export const CalculationLogDialog = (props: CalculationLogDialogProps) => {
  const {
    calculationDetails,
    open,
    onClose
  } = props;

  return (
    <Dialog open={open}>
      <DialogHeader title="Calculation Details" onClose={onClose} />
      <DialogBody>
        <pre style={{ whiteSpace: 'pre-wrap', overflowY: 'scroll' }}>
          {calculationDetails?.log}
        </pre>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
