import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { resetUserPassword } from '@/features/user-settings/api/reset-user-password';
import { notifyError, notifySuccess } from '@/lib/notification/notifications';

export const UserSettingsResetPassword = () => {
  const [ passwordDialogOpen, setPasswordDialogOpen ] = useState(false);

  const passwordMutation = useMutation({
    mutationFn: () => resetUserPassword(),
    onSuccess: () => {
      notifySuccess({ title: 'Success', message: 'Change password email was sent.' });
    },
    onError: () => {
      notifyError({ title: 'Uh Oh', message: 'Change password email failed to send.' });
    },
    onSettled: async () => {
      setPasswordDialogOpen(false);
    }
  });

  const handleResetPassword = () => passwordMutation.mutate();

  return(
    <>
      <div>**********</div>
      <Button color="secondary" onClick={() => setPasswordDialogOpen(true)}>Change</Button>

      {/*TODO: Style this dialog.*/}
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)} size="xl">
        <DialogTitle>Change Your Password</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          A link to change your password will be sent to your email.
        </DialogBody>
        <DialogActions>
          <Button color="plain" onClick={() => setPasswordDialogOpen(false)}>Close</Button>
          <Button color="secondary" disabled={passwordMutation.isPending} onClick={handleResetPassword}>Send Link</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
