import { zodResolver } from '@hookform/resolvers/zod';
import { ProtectedRoute } from '@shared/auth';
import { Box } from 'grommet';
import { parseInt } from 'lodash';
import { Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import CheckboxSkeleton from '@/components/loading/checkbox-skeleton';
import FormFieldSkeleton from '@/components/loading/form-field-skeleton';
import TextSkeleton from '@/components/loading/text-skeleton';
import { useClient } from '@/features/clients/api/use-client';
import { useClientUser } from '@/features/clients/api/use-client-user';
import { useUpdateClientUser } from '@/features/clients/api/use-update-client-user';
import CreateUpdateClientUserForm from '@/features/clients/components/create-update-client-user-form';
import { useEditClientUserDismiss } from '@/features/clients/hooks/use-edit-client-user-dismiss';
import {
  CreateUpdateClientUserFormSchema,
  createUpdateClientUserFormSchema
} from '@/features/clients/models/create-update-client-user-form-schema';
import { useNavigate, useParams } from '@/router';
import { Button } from '@/components-new/button';

const EditClientUserDialog = () => {
  const dismiss = useEditClientUserDismiss();
  const navigate = useNavigate();
  const { id, userId } = useParams('/clients/:id/users/:userId/edit');
  const { data: user } = useClientUser(parseInt(id), parseInt(userId));
  const { data: client } = useClient(parseInt(id));
  const { mutate: updateClientUser, isPending } = useUpdateClientUser();

  const form = useForm<CreateUpdateClientUserFormSchema>({
    resolver: zodResolver(createUpdateClientUserFormSchema)
  });

  const handleSubmit = (value: CreateUpdateClientUserFormSchema) => {
    updateClientUser(
      { clientId: parseInt(id), userId: parseInt(userId), form: value },
      {
        onSuccess: () => {
          navigate('/clients/:id/users', { params: { id } });
        }
      }
    );
  };

  const isSubmitDisabled = !form.formState.isValid || isPending;

  return (
    <>
      <DialogHeader
        title="Edit User"
        subtitle={user.name}
        onClose={dismiss}
      />
      <DialogBody>
        <FormProvider {...form}>
          <CreateUpdateClientUserForm client={client} user={user} />
        </FormProvider>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={dismiss}>Cancel</Button>
        <Button disabled={isSubmitDisabled} onClick={form.handleSubmit(handleSubmit)}>Save</Button>
      </DialogActions>
    </>
  );
};

const EditClientUserDialogSkeleton = () => {
  const dismiss = useEditClientUserDismiss();

  return (
    <>
      <DialogHeader
        onClose={dismiss}
        title={<TextSkeleton width="medium" height="2rem" />}
        subtitle={<TextSkeleton width="medium" />}
      />
      <DialogBody>
        <Box gap="medium">
          <FormFieldSkeleton />
          <FormFieldSkeleton />
          <Box gap="small">
            <TextSkeleton width="small" />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
          </Box>
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={dismiss}>Cancel</Button>
        <Button disabled>Save</Button>
      </DialogActions>
    </>
  );
};

const EditClientUserPage = () => {
  const dismiss = useEditClientUserDismiss();

  return (
    <ProtectedRoute policies={['canManageClients']}>
      <Dialog open={true} onClickOutside={dismiss} width="large">
        <Suspense fallback={<EditClientUserDialogSkeleton />}>
          <EditClientUserDialog />
        </Suspense>
      </Dialog>
    </ProtectedRoute>
  );
};

export default EditClientUserPage;

