import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Notification } from '@/features/notifications/types/notification';
import { NotificationStatus } from '@/features/notifications/types/notification-status';
import { artiaProtectedApi } from '@/lib/api-client';

const updateNotificationStatuses = async (notificationStatuses: NotificationStatus[]): Promise<void> => {
  await artiaProtectedApi.patch('notifications', notificationStatuses);
};

export const useUpdateNotificationStatuses = () => {
  const queryClient = useQueryClient();

  return useMutation({
      mutationFn: (statusUpdates: NotificationStatus[]) => updateNotificationStatuses(statusUpdates),
      onMutate: (statusUpdates) => {
        const staleNotifications = queryClient.getQueryData<Notification[]>(['user-notifications']);

        let notificationData: Notification[] = [];
        if (staleNotifications) {
          notificationData = staleNotifications.map(notification => {
            const updatedNotif = statusUpdates.find(statusUpdate => statusUpdate.id === notification.id);
            if (!updatedNotif) return notification;
            if (updatedNotif.delete) return { ...notification, id: 0 };
            return { ...notification, read: updatedNotif.read };
          }).filter(n => n.id !== 0);
        }

        queryClient.setQueryData<Notification[]>(['user-notifications'], notificationData);

        return { notificationData };
      }
    }
  );
};
