import { useAuthorization } from '@shared/auth/use-authorization';
import { useHeaderDetails } from '@shared/header';
import { Anchor, Box, ColumnConfig, DataTable, Text } from 'grommet';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { UnsetLink } from '@/components/unset-link';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Client } from '@/features/clients';
import { ClientsProvider, useClients } from '@/features/clients/clients.provider';
import { ClientFilters } from '@/features/clients/components/client-filters';
import { DeleteClientDialog } from '@/features/clients/components/delete-client-dialog';
import { insertIf } from '@/utils/arrays';
import { HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi2';
import { Button } from '@/components-new/button';

const ClientsPageContainer = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { clients, loadClients, loadingClients } = useClients();
  const navigate = useNavigate();
  const { checkPolicies } = useAuthorization();
  const canManageClients = checkPolicies(['canManageClients']);

  const [filters, setFilters] = useFilters<{ name?: string }>('clients', {});

  const filteredClients = useMemo(() => {
    const { name } = filters;

    return clients.filter(client =>
      !name || client.name.toLowerCase().includes(name.toLowerCase())
    );
  }, [filters, clients]);

  React.useEffect(() => {
    loadClients();

    setHeaderDetails({
      documentTitle: 'Clients',
      pageTitle: (
        <PageTitleRow title="Clients">
          {canManageClients && (
            <Button
              aria-label="Add Client"
              onClick={onCreateClient}
            >
              <HiOutlinePlus/>
              Add New
            </Button>
          )}
        </PageTitleRow>
      ),
      breadcrumbs: []
    });

    return () => {
      clearHeaderDetails();
    };

    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateClient = () => {
    navigate('/clients/new');
  };

  const onEditClient = (id: number) => {
    navigate(`/clients/${id}/edit`, {
      state: { referrer: '/clients' }
    });
  };

  const [ deletingClientId, setDeletingClientId ] = useState<number>();
  const [ deletingClientName, setDeletingClientName ] = useState<string>();

  const handleDelete = (client: Client) => {
    setDeletingClientId(client.id);
    setDeletingClientName(client.name);
  };

  const handleCancelDelete = () => {
    setDeletingClientId(undefined);
    setDeletingClientName(undefined);
  };

  const handleDeleteSuccessful = () => {
    setDeletingClientId(undefined);
    setDeletingClientId(undefined);
    loadClients();
  };

  const columns: ColumnConfig<Client>[] = [
    {
      property: 'name',
      header: 'Name',
      render: (client: Client) => (
        canManageClients
          ? (
            <Box direction="row" gap="xxsmall">
              <UnsetLink to={`/clients/${client.id}`}>
                <Anchor as={Text} label={client.name}/>
              </UnsetLink>
            </Box>
        )
          : (<Text weight="bold">{client.name}</Text>)
      )
    },
    ...insertIf(
      canManageClients,
      {
        property: 'actions',
        sortable: false,
        size: 'xsmall',
        render: (client: Client) => (
          <Box direction="row" gap="xxsmall">
            <Button
              plain
              title="Edit"
              onClick={() => onEditClient(client.id)}
            >
              <HiOutlinePencil/>
            </Button>
            <Button
              plain
              title="Delete"
              onClick={() => handleDelete(client)}
            >
              <HiOutlineTrash/>
            </Button>
          </Box>
        )
      }
    )
  ];

  return (
    <Box pad={{ bottom: 'medium' }}>
      <Box margin={{ top: 'small' }} gap="small">
        <ClientFilters defaultValue={filters} onApplyFilters={setFilters} />
        <DataTable
          columns={columns}
          data={filteredClients}
          sortable
          step={10}
          paginate
          placeholder={
            (loadingClients || filteredClients.length === 0) &&
            <Box fill>
              {loadingClients && <TableLoadingOverlay />}
              {!loadingClients && filteredClients.length === 0 && <TableEmptyPlaceholder content="No Client data is available." />}
            </Box>
          }
        />
      </Box>

      <DeleteClientDialog
        open={!!deletingClientId}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        clientId={deletingClientId}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        name={deletingClientName}
        onClose={handleCancelDelete}
        onDeleteComplete={handleDeleteSuccessful}
      />
    </Box>
  );
};

const ClientsPage = () => {
  return (
    <ClientsProvider>
      <ClientsPageContainer />
    </ClientsProvider>
  );
};

export default ClientsPage;
