import { useErrorHandler } from '@shared/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeClientUsersQueryKey } from '@/features/clients/api/use-client-users';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { CreateUpdateClientUserFormSchema } from '@/features/clients/models/create-update-client-user-form-schema';
import { notifySuccess } from '@/lib/notification/notifications';

export const useCreateClientUser = () => {
  const { createClientUser } = useClientService();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, form }: { clientId: number, form: CreateUpdateClientUserFormSchema }) => createClientUser(clientId, form),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'User was successfully created.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to create user.' });
    },
    onSettled: async (_, __, { clientId }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientUsersQueryKey(clientId) });
    }
  });
};
