import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { meetingSchema } from '@/features/calendar/types/meeting';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

const queryKeys = {
  all: ['calendar'],
  list: (filters: GetCalenderFilters) => [...queryKeys.all, { ...filters }],
};

// get calender
type GetCalenderFilters = {
  month: string;
};

const meetingsSchema = z.array(meetingSchema);

const fetchCalendar = async (filters: GetCalenderFilters) => {
  const data = await artiaProtectedApi.get(`calendar?month=${filters.month}`);

  return meetingsSchema.parse(data);
};

const calendarQueryOptions = (filters: GetCalenderFilters) => {
  return queryOptions({
    queryKey: [...queryKeys.list(filters)],
    queryFn: () => fetchCalendar(filters)
  });
};

type UseCalendarQueryOptions = {
  filters: GetCalenderFilters;
  queryConfig?: QueryConfig<typeof calendarQueryOptions>
};

export const useCalendarQuery = ({ filters, queryConfig }: UseCalendarQueryOptions) => {
  return useQuery({
    ...calendarQueryOptions(filters),
    ...queryConfig
  });
};
