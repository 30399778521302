import { useAuthorization } from '@shared/auth/use-authorization';
import { Box, Text } from 'grommet';
import React from 'react';

import Nullish from '@/components/nullish';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { TextLink } from '@/components-new/text';

type TrackedByCellProps = {
  row: StateCoverage
}

const TrackedByCell = ({ row }: TrackedByCellProps) => {
  const { checkPolicies } = useAuthorization();
  const canManageClients = checkPolicies(['canManageClients']);

  const { trackedByClients } = row;

  return (
    <Nullish
      value={trackedByClients}
      valueRenderer={(clients) =>
        canManageClients
          ? (
            <Box direction="column" gap="xsmall">
              {clients.map(({ id, name }) => (
                <TextLink
                  key={id}
                  href={`/clients/${id}`}
                  state={{ referrer: '/drug-coverage' }}
                >
                  {name}
                </TextLink>
              ))}
            </Box>
          )
          : (
            <Box direction="column" gap="xsmall">
              {clients.map(({ id, name }) => (
                <Text key={id}>{name}</Text>
              ))}
            </Box>
          )
      }
    />
  );
};

export default TrackedByCell;
