import { Box, FormField, Heading, Text, TextInput } from 'grommet';
import React, { ReactNode, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useBlocker, useNavigate } from 'react-router-dom';

import { Busy } from '@/components/busy';
import { ConfirmationDialog } from '@/components/confirmation-dialog';
import { Lookup } from '@/types/lookup';

import { ColumnConfigurationUsage } from '../../types';
import { useStatesProvider } from '../states-provider';
import { AddColumnsForm } from './add-columns-form';
import { ColumnDnd } from './column-dnd';
import { Button } from '@/components-new/button';

type CustomStateReportFormProps = {
  /**
   * The id of form, required. Used to identify the form in the DOM.
   */
  id: string;

  /**
   * Default values for the form, defaults to an empty form. Used to pre-populate the form with existing data.
   */
  defaultValues?: {
    name: string;
    columns: Lookup<string>[];
  };

  /**
   * The heading to display at the top of the form, required.
   */
  heading: string;

  /**
   * Function to call when the form is submitted
   * @param data submitted values from the form
   */
  onSubmit: (data: { name: string; columns: Lookup<string>[] }) => Promise<void>;

  /**
   * Buttons to render at the top of the page, required.
   * Usually used for the submit button, cancel button, and a delete button if they're necessary.
   */
  children?: ReactNode;
};

/**
 * Form for creating or editing a custom state report
 */
export const CustomStateReportForm = (props: CustomStateReportFormProps) => {
  const {
    id,
    defaultValues = {
      name: '',
      columns: []
    },
    onSubmit,
    heading,
    children
  } = props;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues
  });

  const blocker = useBlocker(({ nextLocation }) => nextLocation.state !== 'skipBlock' && isDirty);

  const currentlySelectedColumns = watch('columns');

  const {
    columnGroups: { systemReports }
  } = useStatesProvider();

  const allColumns = useMemo(() => {
    return Object.values(systemReports)
      .flatMap(
        (group) => group.columnConfigurations
          .filter((column) => column.usages.includes(ColumnConfigurationUsage.VIEW_FROM_TABLE))
          .map<Lookup<string>>((column) => ({
            id: column.propertyName,
            label: column.header
          }))
      )
      .filter((column) => !currentlySelectedColumns.some((c) => c.id === column.id));
  }, [systemReports, currentlySelectedColumns]);

  const [columnsToAdd, setColumnsToAdd] = useState<Lookup<string>[]>([]);

  return (
    <Box
      as="form"
      id={id}
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          navigate('/state-reports/manage-reports', { state: 'skipBlock' });
        } catch (_) {
          // JF: swallow the error since `isSubmitting` won't turn off otherwise?????
        }
      })}
      direction="column"
      gap="medium"
      pad="large"
    >
      <Heading level={2}>{heading}</Heading>
      <Controller
        name="name"
        control={control}
        rules={{ required: 'Name is required' }}
        render={({ field: { name, onChange, onBlur, ref, value }, fieldState: { error } }) => (
          <FormField label="Name" name={name} required margin="none" error={error?.message}>
            <TextInput name={name} onChange={onChange} onBlur={onBlur} ref={ref} value={value}/>
          </FormField>
        )}
      />

      <AddColumnsForm
        value={columnsToAdd}
        onChange={setColumnsToAdd}
        options={allColumns}
        onAdd={() => {
          setValue(
            'columns',
            [...currentlySelectedColumns, ...columnsToAdd],
            { shouldDirty: true }
          );
          setColumnsToAdd([]);
        }}
      />

      {currentlySelectedColumns.length > 0 ? (
        <ColumnDnd
          value={currentlySelectedColumns}
          onChange={(newColumns) => setValue('columns', newColumns, { shouldDirty: true })}
        />
      ) : null}

      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', alignItems: 'center' }}>
        {children ?? <span></span>}
        <Box direction="row" gap="small" align="end">
          <Button
            plain
            disabled={isSubmitting}
            className="size-max"
            onClick={() => {
              navigate('/state-reports/manage-reports', { state: 'skipBlock' });
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid || isSubmitting}
            form={id}
            type="submit"
            className="size-max"
          >
            <Busy busy={isSubmitting} content="Save" />
          </Button>
        </Box>
      </div>
      <ConfirmationDialog
        open={blocker.state === 'blocked'}
        title="You have unsaved changes"
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onCancel={() => blocker.reset()}
        onConfirm={() => {
          reset({
            name: '',
            columns: []
          });
          setColumnsToAdd([]);
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          blocker.proceed();
        }}
      >
        <Text>Are you sure you want to discard your unsaved changes?</Text>
      </ConfirmationDialog>
    </Box>
  );
};
