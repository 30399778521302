import { Box } from 'grommet';
import { Revert } from 'grommet-icons';
import React from 'react';

import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';
import { Button } from '@/components-new/button';
import { HiOutlineArchiveBoxArrowDown, HiOutlineBookOpen, HiOutlineTrash } from 'react-icons/hi2';

type BidAnalysesRowActionsCellProps = {
  bidAnalysisId: number;

  /**
   * The status of the bid analysis.
   */
  forStatus: BidAnalysisStatusType;

  /**
   * Called when the user clicks the close bid analysis button.
   */
  onCloseBidAnalysis: (bidAnalysisId: number) => void;

  /**
   * Called when the user clicks the reopen bid analysis button.
   */
  onReopenBidAnalysis: (bidAnalysisId: number) => void;

  /**
   * Whether the user should be given the option to delete the bid analysis.
   */
  canDelete: boolean;

  /**
   * Called when the user clicks the delete bid analysis button.
   */
  onDeleteBidAnalysis: (bidAnalysisId: number) => void;
};

/**
 * Displays a set of actions based on the status of the bid analysis.
 */
export const BidAnalysesRowActionsCell = (props: BidAnalysesRowActionsCellProps) => {
  const {
    bidAnalysisId,
    forStatus,
    onCloseBidAnalysis,
    onReopenBidAnalysis,
    canDelete,
    onDeleteBidAnalysis
  } = props;

  const handleCloseBidAnalysis = () => {
    onCloseBidAnalysis(bidAnalysisId);
  };

  const handleOpenBidAnalysis = () => {
    onReopenBidAnalysis(bidAnalysisId);
  };

  const handleDeleteBidAnalysis = () => {
    onDeleteBidAnalysis(bidAnalysisId);
  };

  const isClosed = forStatus === BID_ANALYSIS_STATUS_CLOSED;
  const isOpen = forStatus === BID_ANALYSIS_STATUS_OPEN;
  return (
    <Box direction="row" align="center" gap="xsmall">
      {isOpen && <OpenStatusActions onCloseBidAnalysisClick={handleCloseBidAnalysis} />}
      {isClosed && <ClosedStatusActions onOpenBidAnalysisClick={handleOpenBidAnalysis} />}

      {canDelete && <Button
        plain
        title="Delete Bid Analysis"
        aria-label="Delete Bid Analysis"
        onClick={handleDeleteBidAnalysis}
      >
        <HiOutlineTrash/>
      </Button>}
    </Box>
  );
};

type OpenStatusActionsProps = {
  onCloseBidAnalysisClick: () => void;
};

/**
 * Actions for when the bid analysis is open.
 */
const OpenStatusActions = ({ onCloseBidAnalysisClick }: OpenStatusActionsProps) => {
  return (
    <Button
      plain
      title="Close Bid Analysis"
      aria-label="Close Bid Analysis"
      onClick={onCloseBidAnalysisClick}
    >
      <HiOutlineArchiveBoxArrowDown/>
    </Button>
  );
};


type ClosedStatusActionsProps = {
  onOpenBidAnalysisClick: () => void;
};

/**
 * Actions for when the bid analysis is closed.
 */
const ClosedStatusActions = ({ onOpenBidAnalysisClick }: ClosedStatusActionsProps) => {
  return (
    <Button
      plain
      title="Reopen Bid Analysis"
      aria-label="Reopen Bid Analysis"
      onClick={onOpenBidAnalysisClick}
    >
      <HiOutlineBookOpen/>
    </Button>
  );
};
